<template>
  <div class="container">
    <div class="center">
      <div class="color mb-20">
        注意：仅限企业自有账号配置，不配置则默认使用增值平台账号
      </div>
      <a-form :labelCol="{ span: 4 }" ref="formRef" :model="formData">
        <a-form-item
          label="服务项"
          name="service_group"
          :rules="{
            required: true,
            message: '不能为空',
            trigger: 'change'
          }"
        >
          <a-select
            v-model:value="formData.service_group"
            :options="serveList"
          ></a-select>
        </a-form-item>
        <a-form-item
          label="服务商"
          name="service_provider_code"
          :rules="{
            required: true,
            message: '不能为空',
            trigger: 'change'
          }"
        >
          <a-select v-model:value="formData.service_provider_code">
            <a-select-option
              v-for="(item, index) in provideList"
              :key="index"
              :value="item.code"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          v-for="(item, index) in Object.keys(formData.account_ext)"
          :key="index"
          :label="getName(item)"
          :name="['account_ext', item]"
          :rules="{
            required: true,
            message: '不能为空',
            trigger: 'change'
          }"
        >
          <a-input v-model:value="formData.account_ext[item]" />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 6 }">
          <a-button @click="resetForm">清空</a-button>
          <a-button type="primary" style="margin-left: 10px" @click="saveValue"
            >保存</a-button
          >
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, watch } from "vue";
import { useServiceEnumApi } from "@/apis/enums";
import {
  useProviderApi,
  useConfigureApi,
  useConfigureDetailApi
} from "@/apis/system";
import { message } from "ant-design-vue";

export default defineComponent({
  setup() {
    const serviceEnumApi = useServiceEnumApi();
    const providerApi = useProviderApi();
    const configureApi = useConfigureApi();
    const configureDetailApi = useConfigureDetailApi();
    const formRef = ref();
    const formData = ref({
      account_ext: {},
      service_group: "",
      service_provider_code: ""
    });
    const rules = ref({
      service_group: [
        { required: true, message: "服务项不能为空", trigger: "blur" }
      ],
      service_provider_code: [
        {
          required: true,
          message: "服务商不能为空",
          trigger: "blur"
        }
      ]
    });

    const resetForm = () => {
      formRef.value.resetFields();
    };

    watch(
      () => formData.value.service_group,
      () => {
        getProvide();
        formData.value.service_provider_code = "";
      }
    );
    // 服务商列表
    const provideList = ref([]);
    const getProvide = async () => {
      const res = await providerApi({
        service_group: formData.value.service_group
      });
      provideList.value = res;
    };
    //服务列表
    const serveList = ref([]);
    const getServiceList = async () => {
      const res = await serviceEnumApi({
        code: "configurable_account_service"
      });
      serveList.value = res;
    };
    // 设置保存
    const saveConfigure = async () => {
      const res = await configureApi({
        ...formData.value
      });
      console.log(res);
      message.success("添加成功");
      resetForm();
    };

    // 获取字典
    const enums = ref([]);
    // 获取服务项详情
    const configDetail = async () => {
      if (
        formData.value.service_group &&
        formData.value.service_provider_code
      ) {
        const emun = await serviceEnumApi({
          code: `${formData.value.service_group}_${formData.value.service_provider_code}`
        });
        enums.value = emun;
        const res = await configureDetailApi({
          service_group: formData.value.service_group,
          service_provider_code: formData.value.service_provider_code
        });
        formData.value = res;
      }
    };
    watch(
      () => formData.value.service_provider_code,
      () => {
        configDetail();
      }
    );

    const getName = name => {
      if (enums.value.length) {
        const title = enums.value.find(item => item.value === name);
        console.log(title && title.label);
        return title && title.label;
      } else {
        return name;
      }
    };
    // 请求选项数据
    onMounted(() => {
      getServiceList();
    });

    const saveValue = () => {
      formRef.value
        .validate()
        .then(() => {
          saveConfigure();
        })
        .catch(error => {
          console.log("error", error);
        });
    };

    return {
      getServiceList,
      serveList,
      getProvide,
      provideList,
      saveConfigure,
      configDetail,
      formRef,
      rules,
      formData,
      resetForm,
      saveValue,
      getName
    };
  }
});
</script>
<style lang="less" scoped>
.container {
  height: calc(100vh - 100px);
  padding: 20px;
  background: white;
}
.center {
  width: 450px;
  margin: 0 auto;
  padding-top: 20px;
}
.color {
  color: red;
}
</style>
